import React from "react"
import { Link } from "react-router-dom"
// import Footer from "../Common/Footer";
import { withTranslation } from "react-i18next"
import FooterV2 from "../v2/layout/FooterV2"
import newsData from "../../data/news.json"

const ITEMS_PER_PAGE = 6

class News extends React.Component {
  state = {
    posts: [],
    currentPage: 1,
    totalPages: 1,
    error: null,
  }

  componentDidMount() {
    this.fetchBlogPosts(this.state.currentPage)
  }

  async fetchBlogPosts(page) {
    try {
      // Get all data and sort by created_at in descending order
      const allData = newsData.data.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      )

      const totalPages = Math.ceil(allData.length / ITEMS_PER_PAGE)

      // Calculate start and end index for current page
      const startIndex = (page - 1) * ITEMS_PER_PAGE
      const endIndex = startIndex + ITEMS_PER_PAGE

      // Get posts for current page
      const paginatedPosts = allData.slice(startIndex, endIndex)

      this.setState({
        posts: paginatedPosts,
        currentPage: page,
        totalPages: totalPages,
        error: null,
      })
    } catch (error) {
      console.error("Error loading news data:", error)
      this.setState({
        error: this.props.t("news.errors.fetchError"),
      })
    }
  }

  handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= this.state.totalPages) {
      this.fetchBlogPosts(newPage)
      // Scroll to top of the page
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  formatDate = (dateString) => {
    const { i18n } = this.props
    const date = new Date(dateString)
    return date.toLocaleDateString(i18n.language, {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  }

  render() {
    const { posts, currentPage, totalPages, error } = this.state
    const { t } = this.props

    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>{t("news.title")}</h1>
            <span>{t("news.subtitle")}</span>
            <ul>
              <li>
                <Link to="/">{t("news.breadcrumb.home")}</Link>
              </li>
              <li>{t("news.breadcrumb.news")}</li>
            </ul>
          </div>
        </div>

        <section className="blog-area ptb-120">
          <div className="container">
            {error ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              <>
                <div className="row">
                  {posts.map((post) => (
                    <div className="col-lg-4 col-md-6" key={post.id}>
                      <div className="single-blog-post">
                        <div className="blog-image">
                          <Link to={`news/${post.slug}`}>
                            <img src={post.image} alt={post.title} />
                          </Link>

                          <div className="post-tag">
                            <Link to={`news/${post.slug}`}>
                              {post.category.charAt(0).toUpperCase() +
                                post.category.slice(1)}
                            </Link>
                          </div>
                        </div>

                        <div className="blog-post-content">
                          <span className="date">
                            {this.formatDate(post.created_at)}
                          </span>
                          <h3>
                            <Link to={`news/${post.slug}`}>{post.title}</Link>
                          </h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: post.content.slice(0, 150) + "...",
                            }}
                          />
                          <Link
                            to={`news/${post.slug}`}
                            className="read-more-btn"
                          >
                            {t("news.readMore")}{" "}
                            <i className="icofont-double-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="pagination-area">
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault()
                              this.handlePageChange(currentPage - 1)
                            }}
                            aria-label={t("news.pagination.previous")}
                          >
                            <i className="icofont-double-left"></i>
                          </Link>
                        </li>

                        {Array.from({ length: totalPages }, (_, index) => (
                          <li
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            key={index}
                          >
                            <Link
                              className="page-link"
                              to="#"
                              onClick={(e) => {
                                e.preventDefault()
                                this.handlePageChange(index + 1)
                              }}
                              aria-label={`${t("news.pagination.page")} ${
                                index + 1
                              }`}
                            >
                              {index + 1}
                            </Link>
                          </li>
                        ))}

                        <li
                          className={`page-item ${
                            currentPage === totalPages ? "disabled" : ""
                          }`}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault()
                              this.handlePageChange(currentPage + 1)
                            }}
                            aria-label={t("news.pagination.next")}
                          >
                            <i className="icofont-double-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    )
  }
}

export default withTranslation()(News)
