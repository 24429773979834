import React from "react"
import OwlCarousel from "react-owl-carousel3"
import lax from "lax.js"
import "./LatestNewsV2.css"
import { withTranslation } from "react-i18next"
import FunctionalLaxDiv from "../../shared/FunctionalLaxDiv"
import ViewAllNewsBtn from "./ViewAllNewsBtn"
import NewsCard from "../../shared/NewsCard"
import newsData from "../../../../data/news.json"

const options = {
  loop: false,
  nav: false,
  dots: true,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
}

const CACHE_KEY = "latestNewsPosts"
const CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds

class LatestNewsV2 extends React.Component {
  state = {
    posts: [],
    currentPage: 1,
    totalPages: 1,
  }

  componentDidMount() {
    this.fetchBlogPosts(this.state.currentPage)
  }

  async fetchBlogPosts(page) {
    try {
      const { data, current_page, last_page } = newsData.data

      // Sort posts by date in descending order and limit to 8 items
      const sortedPosts = data
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(0, 8)

      const newState = {
        posts: sortedPosts,
        currentPage: current_page,
        totalPages: last_page,
        loading: false,
      }

      this.setState(newState)
    } catch (error) {
      console.error("Error loading news data:", error)
      this.setState({ error: "Failed to load news", loading: false })
    }
  }

  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }
  render() {
    const { t } = this.props
    const { posts } = this.state
    return (
      <section className="news-area-v2">
        <div className="container">
          <div className="news-new-section-title">
            <h2>{t("news.subtitle")}</h2>

            <div className="lax-absolute">
              <FunctionalLaxDiv text="News" dataPreset="driftLeft" />
            </div>

            <ViewAllNewsBtn link={"news"} text={t("general.viewAll")} />
          </div>

          <div className="row blog-slides-wrapper-v2">
            <OwlCarousel
              className="blog-slides owl-carousel owl-theme"
              {...options}
            >
              {posts.map((post) => (
                <NewsCard post={post} key={post.id} />
              ))}{" "}
              {/* space in the end is important for owl carousel */}
            </OwlCarousel>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(LatestNewsV2)
