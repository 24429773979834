// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import Footer from "../Common/Footer";

import React from "react"
import FooterV2 from "../v2/layout/FooterV2"
import { Link } from "react-router-dom"
import galleryData from "../../data/gallery.json"

class GalleryDetails extends React.Component {
  state = {
    galleryItem: null,
  }

  componentDidMount() {
    const { id } = this.props.match.params
    try {
      const item = galleryData.data.data.find(
        (item) => item.id === parseInt(id)
      )
      if (item) {
        this.setState({
          galleryItem: {
            data: item,
          },
        })
      }
    } catch (error) {
      console.error("Error loading gallery item:", error)
    }
  }
  render() {
    const { galleryItem } = this.state
    const styles = {
      videoContainer: {
        position: "relative",
        width: "100%",
        paddingBottom: "56.25%",
        height: 0,
        overflow: "hidden",
      },
      iframe: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      },
    }
    if (!galleryItem) {
      return <div>Loading...</div>
    }
    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>
              {galleryItem.data.title}
              {/* {JSON.stringify(newsArticle)} */}
            </h1>
            {/* <span>Our Latest News</span> */}
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Gallery</li>
            </ul>
          </div>
        </div>

        <section className="blog-details-area ptb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-details">
                  <div className="post-image">
                    {galleryItem.data.type === "image" ? (
                      <img
                        src={galleryItem.data.content}
                        alt="blog"
                        width="100%"
                      />
                    ) : (
                      // <p>{galleryItem.data.content}</p>
                      <div style={styles.videoContainer}>
                        <iframe
                          style={styles.iframe}
                          src={`https://www.youtube.com/embed/${galleryItem.data.content}?autoplay=1`}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      </div>
                    )}
                  </div>

                  <h3>{galleryItem.data.title}</h3>
                  {galleryItem.data.description
                    .split("\n")
                    .map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                </div>

                <div className="post-tag-media">
                  <div className="row h-100 align-items-center">
                    <div className="col-lg-2">
                      <ul className="social-share">
                        <li>
                          <span>Share on:</span>
                        </li>
                        <li>
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                          >
                            <i className="icofont-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                          >
                            <i className="icofont-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://www.instagram.com/?url=${window.location.href}`}
                          >
                            <i className="icofont-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`}
                          >
                            <i className="icofont-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    )
  }
}

export default GalleryDetails
