import React from "react"
import { Link } from "react-router-dom"
// import Footer from "../Common/Footer";
import { withTranslation } from "react-i18next"
import FooterV2 from "../v2/layout/FooterV2"
import galleryData from "../../data/gallery.json"

const ITEMS_PER_PAGE = 6

class Gallery extends React.Component {
  state = {
    galleryData: [],
    currentPage: 1,
    totalPages: 1,
    error: null,
  }

  componentDidMount() {
    this.loadGalleryData(this.state.currentPage)
  }

  loadGalleryData(page) {
    try {
      const allData = galleryData.data.data
      const totalPages = Math.ceil(allData.length / ITEMS_PER_PAGE)

      // Calculate start and end index for current page
      const startIndex = (page - 1) * ITEMS_PER_PAGE
      const endIndex = startIndex + ITEMS_PER_PAGE

      // Get data for current page
      const paginatedData = allData.slice(startIndex, endIndex)

      this.setState({
        galleryData: paginatedData,
        currentPage: page,
        totalPages: totalPages,
        error: null,
      })
    } catch (error) {
      console.error("Error loading gallery data:", error)
      this.setState({
        error: this.props.t("gallery.errors.fetchError"),
      })
    }
  }

  handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= this.state.totalPages) {
      this.loadGalleryData(newPage)
      // Scroll to top of the page
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  render() {
    const { galleryData, currentPage, totalPages, error } = this.state
    const { t } = this.props

    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>{t("gallery.title")}</h1>
            <ul>
              <li>
                <Link to="/">{t("gallery.breadcrumb.home")}</Link>
              </li>
              <li>{t("gallery.breadcrumb.gallery")}</li>
            </ul>
          </div>
        </div>

        <section className="blog-area ptb-120 bg-image">
          <div className="container">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            <div className="row">
              {galleryData.map((item) => (
                <div className="col-lg-6 col-md-6" key={item.id}>
                  <div className="single-blog-card">
                    <Link to={`/gallery/${item.id}`}>
                      {item.type === "image" ? (
                        <div
                          style={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0,
                          }}
                        >
                          <img
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              border: 0,
                            }}
                            src={item.content}
                            alt={item.title}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            height: 0,
                          }}
                        >
                          <iframe
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              border: 0,
                            }}
                            src={`https://www.youtube.com/embed/${item.content}?autoplay=1&mute=1`}
                            title={item.title}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                          />
                        </div>
                      )}
                    </Link>

                    <div className="blog-post-content">
                      <h3>
                        <Link to={`/gallery/${item.id}`}>{item.title}</Link>
                      </h3>
                      <p>{item.description.slice(0, 200)}...</p>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-lg-12 col-md-12">
                <div className="pagination-area">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault()
                            this.handlePageChange(currentPage - 1)
                          }}
                          aria-label={t("gallery.pagination.previous")}
                        >
                          <i className="icofont-double-left"></i>
                        </Link>
                      </li>

                      {Array.from({ length: totalPages }, (_, index) => (
                        <li
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                          key={index}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault()
                              this.handlePageChange(index + 1)
                            }}
                            aria-label={`${t("gallery.pagination.page")} ${
                              index + 1
                            }`}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      ))}

                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault()
                            this.handlePageChange(currentPage + 1)
                          }}
                          aria-label={t("gallery.pagination.next")}
                        >
                          <i className="icofont-double-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        <FooterV2 />
      </React.Fragment>
    )
  }
}

export default withTranslation()(Gallery)
