// import React, { useEffect, useState } from "react"
// import { Link, useParams } from "react-router-dom"
// import Footer from "../Common/Footer"
import React from "react"
import FooterV2 from "../v2/layout/FooterV2"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import newsData from "../../data/news.json"

class NewsDetail extends React.Component {
  state = {
    newsArticle: null,
    error: null,
  }

  componentDidMount() {
    const { slug } = this.props.match.params
    this.loadNewsArticle(slug)
  }

  loadNewsArticle(slug) {
    try {
      const article = newsData.data.data.find((post) => post.slug === slug)

      if (article) {
        this.setState({
          newsArticle: article,
          error: null,
        })
      } else {
        this.setState({
          error: this.props.t("news.errors.notFound"),
          newsArticle: null,
        })
      }
    } catch (error) {
      console.error("Error loading news article:", error)
      this.setState({
        error: this.props.t("news.errors.loadError"),
        newsArticle: null,
      })
    }
  }

  formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  render() {
    const { newsArticle, error } = this.state
    const { t } = this.props

    if (error) {
      return (
        <React.Fragment>
          <div className="page-title-area item-bg2">
            <div className="container">
              <h1>{t("news.error")}</h1>
              <ul>
                <li>
                  <Link to="/">{t("news.breadcrumb.home")}</Link>
                </li>
                <li>
                  <Link to="/news">{t("news.breadcrumb.news")}</Link>
                </li>
                <li>{t("news.error")}</li>
              </ul>
            </div>
          </div>
          <section className="blog-details-area ptb-120">
            <div className="container">
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            </div>
          </section>
          <FooterV2 />
        </React.Fragment>
      )
    }

    if (!newsArticle) {
      return null // or a loading spinner
    }

    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>{newsArticle.title}</h1>
            <ul>
              <li>
                <Link to="/">{t("news.breadcrumb.home")}</Link>
              </li>
              <li>
                <Link to="/news">{t("news.breadcrumb.news")}</Link>
              </li>
              <li>{newsArticle.title}</li>
            </ul>
          </div>
        </div>

        <section className="blog-details-area ptb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="blog-details">
                  <div className="article-img">
                    <img
                      src={newsArticle.image}
                      alt={newsArticle.title}
                      className="w-100"
                    />
                  </div>

                  <div className="article-content">
                   
                    <h3>{newsArticle.title}</h3>

                    <div
                      className="article-text"
                      dangerouslySetInnerHTML={{ __html: newsArticle.content }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <FooterV2 />
      </React.Fragment>
    )
  }
}

export default withTranslation()(NewsDetail)
